import { DarkSection } from '../Section';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function SectionApps() {
	const data = useStaticQuery(graphql`
		{
			crossdigitspromo: file(relativePath: { eq: "cross-digits-promo.png" }) {
				childImageSharp {
					gatsbyImageData(quality: 100, layout: FULL_WIDTH)
				}
			}
			appstore: file(relativePath: { eq: "app-store-download.png" }) {
				childImageSharp {
					gatsbyImageData(width: 274, height: 91, quality: 100, placeholder: NONE, layout: FIXED)
				}
			}
			googleplay: file(relativePath: { eq: "google_play_badge.png" }) {
				childImageSharp {
					gatsbyImageData(width: 310, height: 120, quality: 100, placeholder: NONE, layout: FIXED)
				}
			}
		}
	`);

	return (
		<DarkSection id="apps" className="text-center">
			<h3 className="font-oswald uppercase text-3xl tracking-widest mb-20 text-white sm:px-20 px-10">
				Mobile App Development
			</h3>
			<div className="sm:px-20 px-10 grid grid-cols-1 gap-8 sm:grid-cols-2">
				<div className="flex items-center">
					<GatsbyImage
						image={data.crossdigitspromo.childImageSharp.gatsbyImageData}
						className="w-full"
						alt="Cross Digits - Number Crossword Puzzles"
					/>
				</div>
				<div className="flex flex-col justify-center items-center">
					<h2 className="font-raleway text-4xl text-white tracking-widest">Cross Digits</h2>
					<p className="text-xl my-6">Number Crossword Puzzles</p>
					<a href="https://apps.apple.com/us/app/cross-digits/id329937860">
						<GatsbyImage
							image={data.appstore.childImageSharp.gatsbyImageData}
							alt="Download on the App Store"
							className="m-8"
						/>
					</a>
					<a href="https://play.google.com/store/apps/details?id=ca.princeproductions.crossdigits&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
						<GatsbyImage image={data.googleplay.childImageSharp.gatsbyImageData} alt="Get it on Google Play" />
					</a>
				</div>
			</div>
		</DarkSection>
	);
}
