import { LightSection } from '../Section';
import React, { useState } from 'react';
import NetlifyForm from '../NetlifyForm';

export default function SectionContact() {
	const [formState, setFormState] = useState('default'); // 'default' | 'processing' | 'success' | 'error'
	const preSubmit = async () => {
		if (
			formValues.first_name.length > 0 &&
			formValues.last_name.length > 0 &&
			formValues.email.length > 0 &&
			formValues.how_can_we_help.length > 0
		) {
			setFormState('processing');
			return true;
		} else {
			return false;
		}
	};

	const postSubmit = () => {
		if (formState !== 'error') {
			setFormState('success');
		}
	};

	const errorHandler = (e: React.SyntheticEvent) => {
		console.log('error happened');
		setFormState('error');
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setFormValues({ ...formValues, [e.target.name]: e.target.value });

	const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
		setFormValues({ ...formValues, [e.target.name]: e.target.value });
	const [formValues, setFormValues] = useState({
		first_name: '',
		last_name: '',
		email: '',
		how_can_we_help: '',
	});

	return (
		<LightSection id="contact">
			<div className="relative">
				<div className="relative pt-12 pb-16 px-4 flex flex-row justify-center items-center">
					<div className="w-96">
						<div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
							<h3 className="font-oswald uppercase text-3xl tracking-widest mb-20 text-gray-800 dark:text-gray-200">
								Contact
							</h3>
							{formState !== 'success' && (
								<NetlifyForm
									formName="contact"
									formValues={formValues}
									preSubmit={preSubmit}
									postSubmit={postSubmit}
									errorHandler={errorHandler}
									automaticHoneypot={true}
									className="mt-8 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
									{formState === 'error' && (
										<span className="block text-center px-4 py-4 -mt-4 rounded-md text-sm font-medium bg-red-100 text-red-800">
											We ran into a problem. Please try again.
										</span>
									)}
									<div className="mt-2">
										<label htmlFor="first_name" className="block font-medium text-gray-800 mt-1 dark:text-gray-200">
											First name
										</label>
										<div className="mt-1">
											<input
												type="text"
												name="first_name"
												id="first_name"
												autoComplete="given-name"
												className="block w-full shadow-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md dark:bg-gray-600 dark:text-gray-200"
												value={formValues.first_name}
												onChange={handleChange}
												required
											/>
										</div>
									</div>
									<div className="mt-2">
										<label htmlFor="last_name" className="block font-medium text-gray-800 mt-1 dark:text-gray-200">
											Last name
										</label>
										<div className="mt-1">
											<input
												type="text"
												name="last_name"
												id="last_name"
												autoComplete="family-name"
												className="block w-full shadow-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md dark:bg-gray-600 dark:text-gray-200"
												value={formValues.last_name}
												onChange={handleChange}
												required
											/>
										</div>
									</div>
									<div className="sm:col-span-2 mt-2">
										<label htmlFor="email" className="block font-medium text-gray-800 mt-1 dark:text-gray-200">
											Email
										</label>
										<div className="mt-1">
											<input
												id="email"
												name="email"
												type="email"
												autoComplete="email"
												className="block w-full shadow-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md dark:bg-gray-600 dark:text-gray-200"
												value={formValues.email}
												onChange={handleChange}
												required
											/>
										</div>
									</div>
									<div className="sm:col-span-2 mt-2">
										<div className="flex justify-between mt-1">
											<label htmlFor="how_can_we_help" className="block font-medium text-gray-800 dark:text-gray-200">
												How may I help you?
											</label>
											<span
												id="how_can_we_help_description"
												className="text-gray-600 sm:block hidden dark:text-gray-300">
												Max. 500 characters
											</span>
										</div>
										<div className="mt-1">
											<textarea
												id="how_can_we_help"
												name="how_can_we_help"
												aria-describedby="how_can_we_help_description"
												rows={4}
												className="block w-full shadow-sm focus:ring-green-500 focus:border-green-500 border-gray-300 rounded-md dark:bg-gray-600 dark:text-gray-200"
												value={formValues.how_can_we_help}
												onChange={handleTextAreaChange}
												required></textarea>
										</div>
									</div>
									<div className="text-right sm:col-span-2 mt-2">
										<input type="hidden" name="form-name" value="contact" />
										<button
											type="submit"
											disabled={formState === 'processing'}
											className="inline-flex justify-center mt-1 py-2 px-4 border border-transparent shadow-sm text-lg rounded-md text-white bg-green-700 disabled:opacity-50 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 dark:bg-green-500">
											Submit
										</button>
									</div>
								</NetlifyForm>
							)}
							{formState === 'success' && (
								<div
									className="block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
									role="dialog"
									aria-modal="true"
									aria-labelledby="modal-headline">
									<div>
										<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
											<svg
												className="h-6 w-6 text-green-600"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												aria-hidden="true">
												<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
											</svg>
										</div>
										<div className="mt-3 text-center sm:mt-5">
											<h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
												Thank you!
											</h3>
											<div className="mt-2">
												<p className="text-sm text-gray-500">We will be in touch soon.</p>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</LightSection>
	);
}
