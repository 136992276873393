import * as React from 'react';
import { Logo } from './Logo';

export const Footer = () => (
	<footer className="bg-gradient-to-t from-gray-700 to-gray-800 text-gray-200 py-28">
		<div className="flex flex-row justify-center items-center gap-4">
			<Logo />
			<small className="ml-2 text-lg text-gray-300">&copy; Kieran Prince</small>
		</div>
	</footer>
);

export default Footer;
