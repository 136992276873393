import * as React from 'react';
import SectionHero from '../components/index/SectionHero';
import SectionServices from '../components/index/SectionServices';
import SectionApps from '../components/index/SectionApps';
import SectionContact from '../components/index/SectionContact';
import Footer from '../components/Footer';

// markup
const IndexPage = () => {
	return (
		<div>
			<main>
				<SectionHero />
				<SectionServices />
				<SectionApps />
				<SectionContact />
			</main>
			<Footer />
		</div>
	);
};

export const Head = () => (
	<>
		<meta charSet="UTF-8" />
		<meta httpEquiv="x-ua-compatible" content="ie=edge" />

		<title>Kieran Prince | Web &amp; App Consultant</title>
		<meta name="description" content="Hello I'm Kieran Prince, a web and app consultant in Sarnia, ON, Canada." />
		<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
	</>
);

export default IndexPage;
