import React from 'react';
import { motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export const Logo = (props: any) => {
	const data = useStaticQuery(graphql`
		{
			file(relativePath: { eq: "logo-header.png" }) {
				childImageSharp {
					gatsbyImageData(width: 44, height: 32, quality: 100, placeholder: NONE, layout: FIXED)
				}
			}
		}
	`);

	return (
		<motion.span
			initial="hidden"
			animate="visible"
			whileHover={{
				scale: [1, 0.8, 0.8, 1, 1],
				rotate: [0, 0, 360, 360, 0],
			}}
			whileTap={{ scale: 0.9 }}
			{...props}>
			<GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt="Kieran Prince Logo" />
		</motion.span>
	);
};
