import { LightSection } from '../Section';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

export default function SectionServices() {
	const data = useStaticQuery(graphql`
		{
			kieranprince: file(relativePath: { eq: "kieranprince.png" }) {
				childImageSharp {
					gatsbyImageData(width: 169, height: 170, quality: 100, placeholder: NONE, layout: FIXED)
				}
			}
			servicesdata: file(relativePath: { eq: "services-data.png" }) {
				childImageSharp {
					gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
				}
			}
			servicesanalyst: file(relativePath: { eq: "services-analyst.png" }) {
				childImageSharp {
					gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
				}
			}
			servicesdevelopment: file(relativePath: { eq: "services-development.png" }) {
				childImageSharp {
					gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
				}
			}
			servicesweb: file(relativePath: { eq: "services-web.png" }) {
				childImageSharp {
					gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
				}
			}
		}
	`);

	const ServiceCard = ({ img, title }: { img: IGatsbyImageData; title: string }) => {
		return (
			<div className="bg-white overflow-hidden shadow-lg rounded-lg divide-y divide-gray-200 flex flex-col">
				<div className="px-4 py-8 sm:p-6 md:py-12">
					<div className="sm:h-24 sm:w-24 h-12 w-12 relative mx-auto">
						<GatsbyImage
							image={img}
							alt={title}
							className="transition-opacity duration-200 opacity-75 hover:opacity-100"
						/>
					</div>
				</div>
				<div className="px-4 py-4 sm:px-6 pb-6 bg-gradient-to-t from-gray-700 via-gray-800 to-gray-900 flex flex-grow items-center justify-center">
					<h4 className="text-green-300">{title}</h4>
				</div>
			</div>
		);
	};

	return (
		<LightSection id="services" className="text-center">
			<div className="relative -top-28 -mb-28 sm:-top-32 sm:-mb-32 md:-top-40 md:-mb-40">
				<GatsbyImage
					image={data.kieranprince.childImageSharp.gatsbyImageData}
					alt="Kieran Prince Profile"
					class="mx-auto"
				/>
			</div>
			<h3 className="font-oswald uppercase tracking-widest text-xl mt-4 mb-2 sm:text-3xl sm:mt-10 sm:mb-10 dark:text-gray-200">
				Here's what I've been doing
			</h3>
			<div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4 gap-y-8 px-4 sm:px-8 md:px-20 font-oswald text-lg sm:text-2xl">
				<ServiceCard img={data.servicesweb.childImageSharp.gatsbyImageData} title="Custom Web Development" />
				<ServiceCard img={data.servicesdevelopment.childImageSharp.gatsbyImageData} title="Mobile App Development" />
				<ServiceCard img={data.servicesdata.childImageSharp.gatsbyImageData} title="Data Consulting" />
				<ServiceCard img={data.servicesanalyst.childImageSharp.gatsbyImageData} title="Technical Analysis" />
			</div>
		</LightSection>
	);
}
