import React, { useCallback } from 'react';
import { DarkSection } from '../Section';
import { motion } from 'framer-motion';
import { Logo } from '../Logo';

import Particles from 'react-particles';
import { loadFull } from 'tsparticles';
import { Container, Engine } from 'tsparticles-engine';

export default function SectionHero() {
	// Delay the Particles animation so that its bounds are correct when it starts
	const [hasMounted, setHasMounted] = React.useState(false);
	React.useEffect(() => {
		setTimeout(() => {
			setHasMounted(true);
		}, 1000);
	}, []);

	const particlesInit = useCallback(async (engine: Engine) => {
		// you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
		// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
		// starting from v2 you can add only the features you need reducing the bundle size
		await loadFull(engine);
	}, []);
	const particlesLoaded = useCallback(async (container: Container | undefined) => {}, []);

	return (
		<DarkSection className="h-96 relative flex justify-center items-center bg-gradient-to-t from-gray-700 via-gray-800 to-gray-900">
			{hasMounted && (
				<motion.div
					initial="hidden"
					animate="visible"
					variants={{
						hidden: {
							opacity: 0.3,
						},
						visible: {
							opacity: 1,
							transition: {
								delay: 0.4,
								duration: 1,
							},
						},
					}}>
					<Particles
						id="tsparticles"
						init={particlesInit}
						loaded={particlesLoaded}
						className="absolute top-0 left-0 right-0 bottom-0 object-center object-cover pointer-events-none"
						options={{
							background: {
								color: '#374151',
								opacity: 0.1,
							},
							fpsLimit: 60,
							interactivity: {
								detectsOn: 'canvas',
								events: {
									onClick: {
										enable: true,
										mode: 'push',
									},
									onHover: {
										enable: true,
										mode: 'attract',
									},
									resize: true,
								},
								modes: {
									bubble: {
										distance: 400,
										duration: 2,
										opacity: 0.8,
										size: 40,
									},
									push: {
										quantity: 4,
									},
									repulse: {
										distance: 200,
										duration: 0.4,
									},
								},
							},
							particles: {
								color: {
									value: '#6b7280',
								},
								links: {
									color: '#6b7280',
									distance: 150,
									enable: true,
									opacity: 0.5,
									width: 1,
								},
								collisions: {
									enable: true,
								},
								move: {
									enable: true,
									outModes: {
										default: 'bounce',
									},
									random: false,
									speed: 2,
									straight: false,
								},
								number: {
									density: {
										enable: true,
										area: 800,
									},
									value: 80,
								},
								opacity: {
									value: 0.5,
								},
								shape: {
									type: 'circle',
								},
								size: {
									value: { min: 1, max: 5 },
								},
							},
							detectRetina: true,
						}}
					/>
				</motion.div>
			)}

			<motion.div
				initial="hidden"
				animate="visible"
				variants={{
					hidden: {
						scale: 0.8,
						opacity: 0,
					},
					visible: {
						scale: 1,
						opacity: 1,
						transition: {
							delay: 0.4,
						},
					},
				}}>
				<div className="relative z-10">
					<div className="flex flex-col items-center text-center">
						<p className="font-cursive text-gray-500 text-2xl sm:text-4xl">Hello, I&rsquo;m</p>
						<h1 className="font-oswald text-white text-4xl sm:text-7xl uppercase tracking-widest mb-5 mt-3">
							Kieran Prince
						</h1>
						<h2 className="font-raleway text-green-300 text-lg sm:text-3xl">Web &amp; App Consultant</h2>
					</div>
				</div>
			</motion.div>

			<Logo className="absolute top-6 left-9 md:top-10 md:left-16 z-10" />
		</DarkSection>
	);
}
