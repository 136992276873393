import * as React from 'react';

interface SectionProps {
	children?: React.ReactNode;
	id?: string;
	className?: string;
}

export const DarkSection = ({ children, id, className }: SectionProps) => (
	<section
		id={id}
		className={`bg-gradient-to-t from-gray-700 via-gray-800 to-gray-900 text-gray-200 relative ${className}`}>
		<div className="py-4 sm:py-8 md:py-20 container mx-auto">{children}</div>
	</section>
);

export const LightSection = ({ children, id, className }: SectionProps) => (
	<section id={id} className={`bg-gray-100 dark:bg-gray-500 text-gray-800 relative ${className}`}>
		<div className="py-4 sm:py-8 md:py-20 container mx-auto">{children}</div>
	</section>
);
